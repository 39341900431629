import { FC, useEffect, lazy, Suspense } from "react";
import { Box, Container } from "@chakra-ui/react";
import AOS from "aos";

import { NavbarHeight } from "theme";
import { AboutPageId, WorkPageId } from "utils/useScroll";
import "./style.css";
import "./App.scss";
import "aos/dist/aos.css";

// Lazy-loaded components
const Navbar = lazy(() => import("shared/navbar/Navbar").then((module) => ({ default: module.Navbar })));
const Landing = lazy(() => import("pages/landing/Landing").then((module) => ({ default: module.Landing })));
const PageHeader = lazy(() => import("shared/page-header/PageHeader").then((module) => ({ default: module.PageHeader })));
const Footer = lazy(() => import("shared/footer/Footer").then((module) => ({ default: module.Footer })));
const FeaturedProjects = lazy(() => import("pages/featured-projects/FeaturedProjects").then((module) => ({ default: module.FeaturedProjects })));
const OtherProjects = lazy(() => import("pages/other-projects/OtherProjects").then((module) => ({ default: module.OtherProjects })));
const About = lazy(() => import("pages/about/About").then((module) => ({ default: module.About })));

// Custom loader component
const Loader: FC = () => (
    <div className="custom-loader">
        {/* Your custom loader HTML/CSS */}
        <div className="macbook">
            <div className="inner">
                <div className="screen">
                    <div className="face-one">
                        <div className="camera"></div>
                        <div className="display">
                            <div className="shade"></div>
                        </div>
                        <span>MacBook Air</span>
                    </div>
                </div>
                <div className="macbody">
                    <div className="face-one">
                        <div className="touchpad"></div>
                        <div className="keyboard">
                            <div className="key"></div>
                            {/* Add more keys as needed */}
                        </div>
                    </div>
                    <div className="pad one"></div>
                    <div className="pad two"></div>
                    <div className="pad three"></div>
                    <div className="pad four"></div>
                </div>
            </div>
            <div className="shadow"></div>
        </div>
    </div>
);



const App: FC = () => {
    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <Container h="100%" px={{ base: 6, md: 6, lg: 4 }}>
                <Navbar />

                <Box mt={{ base: "96px", md: NavbarHeight }}>
                    <Landing />
                    <Box id={WorkPageId}>
                        <PageHeader label="Recent Projects" />
                        <FeaturedProjects />

                        <PageHeader id="page-other-projects" label="Activities" />
                        <OtherProjects />
                    </Box>

                    <Box id={AboutPageId}>
                        <PageHeader label="About Me" />
                        <About />
                    </Box>
                </Box>
                <Footer />
            </Container>
        </Suspense>
    );
};

export default App;

import { ColorHues } from "@chakra-ui/react";

/**
 * Generated via https://lyft-colorbox.herokuapp.com/
 */
export const PrimaryColors: ColorHues = {
    50: "#E6F6FF",
    100: "#B3E0FF",
    200: "#80CAFF",
    300: "#4DB4FF",
    400: "#1A9EFF",
    500: "#0077E6",
    600: "#005CB4",
    700: "#004083",
    800: "#002652",
    900: "#001321",
    
};

export const PrimaryDarkColors: ColorHues = {
    50: "#F5F5F5",
    100: "#F0EEEE",
    200: "#ECE7E7",
    300: "#D5CCCD",
    400: "#706868",
    500: "#807777",
    600: "#50494A",
    700: "#2A2727",
    800: "#131212",
    900: "#0A0909",
};
